<template>
  <b-nav
    id="nav-bar"
  >
    <div
      class="top-bar-icon"
    >
      <slot
        name="left"
      />
    </div>
    <b-nav-text
      class="flex-fill"
      id="nav-text"
    >
      <slot />
    </b-nav-text>
    <div
      class="top-bar-icon"
    >
      <slot
        name="right"
      />
    </div>
  </b-nav>
</template>

<script>
export default {
  name: 'BaseTopBar'
}
</script>

<style scoped lang="scss">
#nav-bar {
  background-color: $blue-500;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.top-bar-icon {
  min-width: 50px!important;
  font-size: 18px;
  display: flex;
  align-items: center;
}
#nav-text {
  font-size: 18px;
  color: white;
  text-align: center;
  max-width: 70%;
}
</style>
